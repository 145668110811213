import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../../assets/css/Team.module.css";

interface TeamMember {
  image: string;
  name: string;
  nameKey: string;
  position: string;
  description?: string;
  link?: string;
  social?: string;
}

const TeamMember = ({
  image,
  name,
  position,
  description,
  link,
  social,
}: any) => {
  return (
    <div className={styles.item}>
      <img className={styles.memberImage} src={image} alt="team member" />
      <div className={styles.itemTextWrapper}>
        <p className={styles.itemName}>{name}</p>
        <p className={styles.itemPosition}>{position}</p>
        {/* {description && <p className={styles.itemDescription}>{description}</p>} */}
      </div>
      {link && (
        <a
          className={styles.socialButton}
          rel="noreferrer"
          href={link}
          target="_blank"
        >
          <img
            src={window.location.origin + `/img/social/${social}.svg`}
            alt="social"
          />
        </a>
      )}
    </div>
  );
};

export const Team = () => {
  const { t }: any = useTranslation();
  const [team, setTeam] = React.useState<TeamMember[]>(
    [
      {
        image: window.location.origin + "/img/team/photos/Andrii.png",
        name: t("Andrii Podobailo"),
        nameKey: "Andrii Podobailo",
        position: "Project Manager, Unity/Web Developer",
        link: "https://www.linkedin.com/in/endl/",
        social: "linkedin",
      },
      // {
      //   image: window.location.origin + "/img/team/photos/Dmytro.png",
      //   name: t("Dmytro Osiichuk"),
      //   nameKey: "Dmytro Osiichuk",
      //   position: "SMM Manager",
      //   link: "http://linkedin.com/in/dmytro-osiichuk/",
      //   social: "linkedin",
      // },
      {
        image: window.location.origin + "/img/team/photos/Vitalii.png",
        name: t("Vitalii Boiko"),
        nameKey: "Vitalii Boiko",
        position: "Game Designer, Unity Developer",
        link: "https://www.linkedin.com/in/imp-gang-boss/",
        social: "linkedin",
      },
      {
        image: window.location.origin + "/img/team/photos/Pavlo.png",
        name: t("Pavlo Strilets"),
        nameKey: "Pavlo Strilets",
        position: "Project Manager, Unity Developer",
        link: "http://www.linkedin.com/in/paveneon-strilets",
        social: "linkedin",
      },
      // {
      //   image: window.location.origin + "/img/team/photos/Yuriy.png",
      //   name: t("Yuriy Berun"),
      //   nameKey: "Yuriy Berun",
      //   position: "Unity Developer",
      //   link: "https://www.linkedin.com/in/yura-berun-493b35268/",
      //   social: "linkedin",
      // },
      // {
      //   image: window.location.origin + "/img/team/photos/Illya.png",
      //   name: t("Illya Nakidaliuk"),
      //   nameKey: "Illya Nakidaliuk",
      //   position: "Unity Developer",
      //   link: "https://www.linkedin.com/in/illya-nakidaliuk-4611791aa/",
      //   social: "linkedin",
      // },
      {
        image: window.location.origin + "/img/team/photos/Svitlana.png",
        name: t("Svitlana Tarasiuk"),
        nameKey: "Svitlana Tarasiuk",
        position: "Unity Developer",
        link: "https://www.linkedin.com/in/svitlana-tarasiuk-145464255/",
        social: "linkedin",
      },
      {
        image: window.location.origin + "/img/team/photos/Andrii_Melnychuk.png",
        name: t("Andrii Melnychuk"),
        nameKey: "Andrii Melnychuk",
        position: "Unity Developer",
        link: "https://www.linkedin.com/in/andrii-melnychuk-269191203/",
        social: "linkedin",
      },
      {
        image: window.location.origin + "/img/team/photos/Andrii_Niko.png",
        name: t("Andrii Nikolaienko"),
        nameKey: "Andrii Nikolaienko",
        position: "Unity Developer",
        link: "https://www.linkedin.com/in/nikolaienko-andrii",
        social: "linkedin",
      },
      {
        image: window.location.origin + "/img/team/photos/Viktoria.png",
        name: t("Viktoria Cinko"),
        nameKey: "Viktoria Cinko",
        position: "2D Artist",
        // link: "https://www.linkedin.com/in/svitlana-tarasiuk-145464255/",
        social: "linkedin",
      },
      {
        image: window.location.origin + "/img/team/photos/Vova.png",
        name: t("Volodymyr Kost"),
        nameKey: "Volodymyr Kost",
        position: "Unity Developer",
        link: "https://www.linkedin.com/in/%D0%B2%D0%BE%D0%BB%D0%BE%D0%B4%D0%B8%D0%BC%D0%B8%D1%80-%D0%BA%D0%BE%D1%81%D1%82%D1%8C-b71022227/",
        social: "linkedin",
      },
    ]
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
  );

  React.useEffect(() => {
    setTeam((prev) =>
      prev.map((x: TeamMember) => ({ ...x, name: t(x.nameKey) }))
    );
  }, [t]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <TeamMember {...team[0]} />
        <TeamMember {...team[1]} />
        <TeamMember {...team[2]} />
      </div>
      <div className={styles.row}>
        <TeamMember {...team[3]} />
        <TeamMember {...team[4]} />
        <TeamMember {...team[5]} />
      </div>
      <div className={styles.row}>
        <TeamMember {...team[6]} />
        <TeamMember {...team[7]} />
        {/* <div style={{ width: "100%", marginRight: 40 }}></div> */}
        <div style={{ width: "100%" }}></div>
      </div> 
      {/* <div className={styles.row}>
        <div style={{ width: "100%", marginRight: 40 }}></div>
        <div style={{ width: "100%" }}></div>
      </div> */}
    </div>
  );
};
